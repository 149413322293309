<template>
  <div class="p-4 mt-2 bg-green-700 text-white text-center ">
    Sygnatura została wysłana do weryfikacji. Sprawdź status w systemie eCRUB
  </div>
</template>

<script>
export default {
  name: 'QrSuccessMessage'
}
</script>
