const translates = [
  {
    en: 'QR code parse error, error =',
    pl: 'Wystąpił błąd parsera QR, error ='
  },
  {
    en: 'Error getting userMedia, error =',
    pl: 'Wystąpił błąd podczas uzyskiwania dostępu do kamery, error ='
  },
  {
    en:
      "The device doesn't support navigator.mediaDevices , only supported cameraIdOrConfig in this case is deviceId parameter (string).",
    pl: 'To urządzenie nie wspiera kamery.'
  },
  {
    en: 'Camera streaming not supported by the browser.',
    pl: 'Przeglądarka nie obsługuje strumieniowania z kamery.'
  },
  {
    en: 'Unable to query supported devices, unknown error.',
    pl: 'Nie znaleziono kamery.'
  },
  {
    en:
      'Camera access is only supported in secure context like https or localhost.',
    pl:
      'Dostęp do kamery jest dostępny tylko przy użyciu protokołu https lub localhost.'
  },
  { en: 'Scanner paused', pl: 'Skaner zatrzymany' },
  { en: 'Scanning', pl: 'Trwa skanowanie' },
  { en: 'Idle', pl: 'Tryb bezczynności' },
  { en: 'Error', pl: 'Error' },
  { en: 'Permission', pl: 'Pozwolenie' },
  { en: 'No Cameras', pl: 'Brak kamer' },
  { en: 'Last Match:', pl: 'Ostatnie dopasowanie:' },
  { en: 'Code Scanner', pl: 'Skaner kodów' },
  { en: 'Request Camera Permissions', pl: 'Zezwól na używanie kamery' },
  {
    en: 'Requesting camera permissions...',
    pl: 'Trwa sprawdzenie zezwoleń na użycie kamery...'
  },
  { en: 'No camera found', pl: 'Nie znaleziono kamery' },
  { en: 'Stop Scanning', pl: 'Zatrzymaj skaner' },
  { en: 'Start Scanning', pl: 'Rozpocznij skanowanie' },
  { en: 'Switch On Torch', pl: 'Włącz latarkę' },
  { en: 'Switch Off Torch', pl: 'Wyłącz latarkę' },
  { en: 'Failed to turn on torch', pl: 'Nie udało się włączyć latarki' },
  { en: 'Failed to turn off torch', pl: 'Nie udało się wyłączyć latarki' },
  { en: 'Launching Camera...', pl: 'Uruchamianie kamery...' },
  { en: 'Scan an Image File', pl: 'Skanuj obraz z pliku' },
  {
    en: 'Scan using camera directly',
    pl: 'Skanuj za pomocą kamery'
  },
  { en: 'Camera1', pl: 'Kamera ' },
  { en: 'Camera2', pl: 'Kamera ' },
  { en: 'Camera3', pl: 'Kamera ' },
  { en: 'Camera4', pl: 'Kamera ' },
  { en: 'Camera5', pl: 'Kamera ' },
  { en: 'facing front', pl: 'przednia' },
  { en: 'facing back', pl: 'tylna' },
  { en: 'Select Camera', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (2)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (3)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (4)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (5)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (6)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (7)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (8)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (9)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (10)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (11)', pl: 'Wybierz kamerę: ' },
  { en: 'Select Camera (12)', pl: 'Wybierz kamerę: ' },
  { en: 'Choose Image - No image choosen', pl: 'Wybierz obraz' },
  { en: 'Choose Another', pl: 'Wybierz inny' },
  { en: 'No image choosen', pl: 'Nie wybrano obrazu' },
  { en: 'Anonymous Camera', pl: 'Kamera w trybie anonimowym' },
  { en: 'Or drop an image to scan', pl: 'Lub upuść obraz tutaj' },
  {
    en: 'Or drop an image to scan (other files not supported)',
    pl: 'Lub upuść obraz tutaj (inne pliki nie są wspierane)'
  },
  { en: 'zoom', pl: 'zoom' },
  { en: 'Loading image...', pl: 'Wczytywanie obrazu.' },
  { en: 'Camera based scan', pl: 'Skan za pomocą kamery' },
  { en: 'Fule based scan', pl: 'Skan pełny' },
  { en: 'Powered by ', pl: '' },
  { en: 'Report issues', pl: '' },
  {
    en: 'NotAllowedError: Permission denied',
    pl: 'Błąd zezwoleń: Nie uzyskano dostępu do kamery'
  },
  {
    en: 'NotFoundError: Requested device not found',
    pl: 'Błąd kamery: Nie znaleziono kamery'
  },
  {
    en:
      'NotFoundException: No MultiFormat Readers were able to detect the code.',
    pl: 'Błąd odczytu: Nie udało się odczytać kodu QR'
  }
]

export class Html5QrcodeTranslate {
  observer = null

  constructor(elementById) {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(nodo => {
            this.textNodeTranslate(nodo)
          })
        }
      })
    })

    const config = { childList: true, subtree: true }
    this.observer.observe(document.querySelector(elementById), config)

    this.textNodeTranslate(document.querySelector(elementById))

    return this.observer
  }

  disconnect() {
    this.observer !== null && this.observer.disconnect()
  }

  translate(texto) {
    const translate = translates.find(t => t.en === texto)
    return translate ? translate.pl : texto
  }

  textNodeTranslate(nodo) {
    if (nodo.nodeType === Node.TEXT_NODE) {
      nodo.textContent = this.translate(nodo.textContent.trim())
    } else {
      for (let i = 0; i < nodo.childNodes.length; i++) {
        this.textNodeTranslate(nodo.childNodes[i])
      }
    }
  }
}
