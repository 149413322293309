<template>
  <div class="">
    <div class="w-full h-full border-solid border-black">
      <div id="qr-code-full-region"></div>
    </div>
    <div v-if="!isScannerActive">
      <button
        class="p-4 bg-blue-700 w-full mt-4 text-white hover:bg-blue-600 cursor-pointer"
        @click="createScanner()"
      >
        Przejdź do skanowania
      </button>
    </div>
  </div>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode'
import { Html5QrcodeTranslate } from '../util/Html5QrCodeTranslate'

const DEFAULT_FPS = 10
const DEFAULT_QRBOX = 250

const config = {
  fps: DEFAULT_FPS,
  qrbox: DEFAULT_QRBOX
}

export default {
  name: 'QrScanner',
  data: () => {
    return {
      html5QrcodeScanner: null,
      isScannerActive: false,
      translator: null
    }
  },
  async mounted() {
    await this.$nextTick()
    this.translator = new Html5QrcodeTranslate('#qr-code-full-region')
    removeImages()
    this.createScanner()
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      this.$emit('success', decodedText, decodedResult)
      this.html5QrcodeScanner.clear()
      this.isScannerActive = false
    },
    onScanError(decodedText, decodedResult) {
      this.$emit('error', decodedText, decodedResult)
    },
    createScanner() {
      this.isScannerActive = true
      this.html5QrcodeScanner = new Html5QrcodeScanner(
        'qr-code-full-region',
        config
      )
      this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanError)
    }
  }
}
const removeImages = () => {
  const targetNode = document.querySelector('body')

  const config = { attributes: true, childList: true, subtree: true }

  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        const addedNodes = mutation.addedNodes
        addedNodes.forEach(node => {
          if (node.tagName === 'IMG') {
            node.style.display = 'none'
          }
        })
      }
    }
  }

  const observer = new MutationObserver(callback)

  observer.observe(targetNode, config)
}
</script>

<style>
#html5-qrcode-button-file-selection {
  text-decoration: underline !important;
}

#html5-qrcode-button-camera-permission {
  text-decoration: underline !important;
}

#html5-qrcode-button-camera-start {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 1px;
  text-decoration: underline !important;
}

#html5-qrcode-button-camera-stop {
  margin-top: 10px;
  padding: 1px;
  text-decoration: underline !important;
}

#html5-qrcode-button-camera-permission {
  margin-bottom: 10px;
  padding: 1px;
  text-decoration: underline !important;
}

#html5-qrcode-select-camera {
  display: block;
  padding: 5px;
  width: calc(100% - 10px);
  margin: 5px;
  border: 1px solid black;
}
</style>
