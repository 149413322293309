<template>
  <div class="w-screen h-screen flex place-content-center">
    <div class="md:w-1/4 p-1 mt-4 flex-col place-content-center text-center">
      <div class="flex place-items-center mb-4">
        <a href="https://www.gunb.gov.pl/">
          <img
            style="max-width: 150px;"
            :alt="$t('header_logo')"
            src="../assets/logo.png"
          />
        </a>
        <h1 class="ml-4 text-center font-bold text-2xl text-blue-900">
          Skaner QR
        </h1>
      </div>

      <div
        v-if="isAuthenticated"
        class="p-4 bg-blue-500 text-white text-center"
      >
        Jesteś zalogowany, możesz skanować dokumenty
      </div>
      <div
        v-if="!isAuthenticated"
        class="p-4 bg-orange-600 text-white text-center"
      >
        Nie jesteś zalogowany, lub sesja wygasła. Zeskanuj autoryzujący kod QR z
        aplikacji eCrub
      </div>

      <template v-if="isSuccess">
        <QrSuccessMessage />
      </template>
      <QrScanner @success="onScanSuccess" @error="onScanError" />
      <br />
    </div>
  </div>
</template>

<script>
import API from '../api'
import QrScanner from '../components/QrScanner'
import QrSuccessMessage from '../components/QrSuccessMessage.vue'

export default {
  name: 'QrCodeScanner',
  components: { QrScanner, QrSuccessMessage },
  data: () => {
    return {
      isSuccess: false,
      isAuthenticated: !!localStorage.getItem('qr-token')
    }
  },
  methods: {
    async onScanSuccess(decodedText) {
      console.log('decoded', decodedText)
      if (isToken(decodedText)) {
        this.login(decodedText)
      } else {
        const token = localStorage.getItem('qr-token')
        const res = await API.printoutVerifications
          .post(decodedText, token)
          .catch(err => {
            if (err.response.status === 401) {
              this.logout()
            }
          })
        if (res.status === 200) {
          this.isSuccess = true
          setTimeout(() => {
            this.isSuccess = false
          }, 5000)
        }
      }
    },
    onScanError(decodedText, decodedResult) {
      // TODO
    },
    logout() {
      localStorage.removeItem('qr-token')
      this.isAuthenticated = false
    },
    login(bearer) {
      const token = bearer.split(' ')[1]
      localStorage.setItem('qr-token', token)
      this.isAuthenticated = true
    }
  }
}

function isToken(maybeToken) {
  maybeToken += '' // cast unknown to string
  return maybeToken.includes('Bearer')
}
</script>
